import type { MergedUnion } from "@kablamo/kerosene";
import {
  AnchorIconButton,
  LeftChevron,
  Pagination,
  SkeletonDataTable,
  Spinner,
  media,
  type PageStateChangeHandler,
} from "@kablamooss/geo-ds-core-components";
import React, { useEffect } from "react";
import styled from "styled-components";
import type { UrlObject } from "url";
import type { AppListProjectsMeta } from "../../../.rest-hooks/types/appListProjectsMeta";
import BackButton from "../../util/BackButton/BackButton";
import RecordCountIndicator, {
  SkeletonRecordCountIndicator,
} from "./RecordCountIndicator";

const StyledTableLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

interface StyledHeaderProps {
  $hasBack: boolean;
}

const StyledHeader = styled.div<StyledHeaderProps>`
  display: grid;
  align-items: start;
  gap: 0.5rem;
  grid-template-areas: "back title";

  @media ${media.md} {
    grid-template-columns: ${(p) => (p.$hasBack ? "auto" : "")} minmax(0, 1fr) auto;
  }
`;

const StyledTitle = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  ${(p) => p.theme.typography.variants.h2}
`;

const StyledSubtitle = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const StyledHeading = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledTitleAndSubtitle = styled.div`
  display: grid;
  gap: 0.125rem;
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const StyledSubheader = styled.div`
  display: flex;
  padding: 0;
  gap: 1.5rem;
  justify-content: space-between;
`;

const StyledCount = styled.div`
  @media ${media.lg} {
    margin-right: auto;
  }
`;

const StyledFilters = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex: 1;

  // Negative margin + equal padding is necessary with overflow to ensure outlines are not cut off
  margin: -1rem;
  padding: 1rem;

  /* Hide scrollbar in Internet Explorer 10+ and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Hide scrollbar in Safari and Chrome  */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSearch = styled.div`
  width: 100%;

  @media ${media.md} {
    width: 20rem;
    min-width: 20rem;
  }
`;

const StyledTable = styled.div`
  position: relative;
  margin: -1rem -1rem;
  padding: 0.5rem 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundWeak};
  border-top: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutrals.surfaceBorder};

  @media ${media.lg} {
    margin: 0;
    padding: 0;
    background-color: ${(p) => p.theme.colors.neutrals.background};
    border-top: none;
    border-bottom: none;
    border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  }
`;

const StyledRefine = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem 0 0;

  @media ${media.lg} {
    padding: 0.5rem 0.75rem 0;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  }
`;

type HasPageIndicator = {
  hasPageIndicator: true;
  onPageStateChange: PageStateChangeHandler;
  page: number;
  pageLength: number;
  perPage: number;
};

type NoPageIndicator = {
  hasPageIndicator?: false;
};

type TableLayoutProps = {
  actions?: React.ReactNode;
  backTo?: string | UrlObject;
  beforeChips?: React.ReactNode;
  children: React.ReactNode;
  chips?: React.ReactNode;
  "data-testid"?: string;
  emptyState: React.ReactNode;
  errorState: React.ReactNode;
  isError: React.ReactNode;
  isEmpty: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  meta: AppListProjectsMeta | undefined;
  refineFilter?: React.ReactNode;
  search?: React.ReactNode;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
} & MergedUnion<HasPageIndicator | NoPageIndicator>;

const TableLayout = ({
  actions,
  backTo,
  beforeChips,
  children,
  chips,
  "data-testid": dataTestId,
  emptyState,
  errorState,
  hasPageIndicator,
  isError,
  isEmpty,
  isFetching,
  isLoading,
  meta,
  onPageStateChange,
  page,
  pageLength,
  perPage,
  refineFilter,
  search,
  subtitle,
  title,
}: TableLayoutProps) => {
  const { totalPages = 0, totalRecords = 0 } = meta ?? {};

  useEffect(() => {
    if (
      hasPageIndicator &&
      page > 1 &&
      !pageLength &&
      !isFetching &&
      !isLoading
    ) {
      onPageStateChange({
        page: totalPages || 1,
        perPage,
      });
    }
  }, [
    hasPageIndicator,
    isFetching,
    isLoading,
    onPageStateChange,
    page,
    pageLength,
    perPage,
    totalPages,
  ]);

  useEffect(() => {
    if (hasPageIndicator && page < 1) {
      onPageStateChange({
        page: 1,
        perPage,
      });
    }
  }, [hasPageIndicator, onPageStateChange, page, perPage]);

  const pageIndicator: React.ReactNode = (
    <>
      <RecordCountIndicator
        page={page || 0}
        pageLength={pageLength || 0}
        perPage={perPage || 0}
        variant="weak"
        totalRecords={totalRecords}
        isEmpty={isEmpty}
        size="bodyLg"
      />
      {isFetching && !title && <Spinner size="sm" />}
    </>
  );

  let subtitleContent: React.ReactNode;
  if (subtitle) {
    subtitleContent = (
      <>
        {subtitle}
        {isFetching && !title && <Spinner size="sm" />}
      </>
    );
  } else if (isLoading) {
    subtitleContent = <SkeletonRecordCountIndicator size="bodyLg" />;
  } else if (hasPageIndicator) {
    subtitleContent = pageIndicator;
  }

  let content: React.ReactNode;

  if (isLoading) {
    content = <SkeletonDataTable perPage={perPage} />;
  } else if (isError) {
    content = errorState;
  } else if (isEmpty) {
    content = emptyState;
  } else {
    content = children;
  }

  const hasSubheader = !!search || !!beforeChips || !!chips || !!refineFilter;

  return (
    <StyledTableLayout>
      {(backTo || title || subtitleContent || hasPageIndicator || actions) && (
        <StyledHeader $hasBack={!!backTo}>
          {!!backTo && (
            <BackButton
              href={backTo}
              legacyBehavior
              passHref
              render={(props) => (
                <AnchorIconButton
                  variant="secondary"
                  icon={LeftChevron}
                  label="Back"
                  size="sm"
                  {...props}
                />
              )}
            />
          )}
          <StyledHeading>
            <StyledTitleAndSubtitle>
              <StyledTitle>
                {title}
                {isFetching && !!title && <Spinner size="sm" />}
              </StyledTitle>
              <StyledSubtitle>{subtitleContent}</StyledSubtitle>
            </StyledTitleAndSubtitle>
            {hasPageIndicator && subtitle && pageIndicator}
          </StyledHeading>
          {!!actions && <StyledActions>{actions}</StyledActions>}
        </StyledHeader>
      )}
      <StyledContent>
        {hasSubheader && (
          <StyledSubheader>
            <StyledFilters
              data-testid={dataTestId && `${dataTestId}-chip-list`}
            >
              {beforeChips}
              {!!search && <StyledSearch>{search}</StyledSearch>}
              {chips}
            </StyledFilters>
            {!!refineFilter && <StyledRefine>{refineFilter}</StyledRefine>}
          </StyledSubheader>
        )}
        <StyledTable>{content}</StyledTable>
      </StyledContent>
      {hasPageIndicator && !isEmpty && (
        <StyledFooter>
          <>
            <StyledCount>
              {isLoading ? (
                <SkeletonRecordCountIndicator size="bodyLg" />
              ) : (
                <RecordCountIndicator
                  page={page}
                  pageLength={pageLength}
                  perPage={perPage}
                  size="bodyLg"
                  totalRecords={totalRecords}
                  isEmpty={isEmpty}
                />
              )}
            </StyledCount>
            <Pagination
              onPageStateChange={onPageStateChange}
              page={page}
              perPage={perPage}
              totalPages={totalPages}
            />
          </>
        </StyledFooter>
      )}
    </StyledTableLayout>
  );
};

export default TableLayout;
